import React from 'react'
import Img from "gatsby-image"
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Tags } from '@tryghost/helpers-gatsby'
import { readingTime as readingTimeHelper } from '@tryghost/helpers'
import PlaceHolderImageQuery from './PlaceHolderImage'
import PostCard, { PostCardList } from './PostCard'

const CategoryCard = ({ post, products, color }) => {
    const url = `/tag/${post.slug}/`
    const readingTime = readingTimeHelper(post)
    const hasImage = post.feature_image && post.localImage

    return (
        <div className="post-card">
            <header className={`post-card-header  pb-20 shadow-sm bg-gradient-to-b p-5 from-${color}-100 via-transparent rounded-xl`}>
                <Link to={url} className=" no-underline" >
                    <div className="">
                    <h2 style={{ textDecoration: 'none' }} className={`transition  duration-500 flex flex-row ease-in-out opacity-80 hover:opacity-80 font-sans text-6xl mb-10 text-${color}-900 `}>
                            {post.name}
                        </h2>
                    </div>
                </Link>
                {/* {hasImage
                    ? <Img style={{ width: "100%", height: '20vh', backgroundColor: 'transparent' }} imgStyle={{ objectFit: 'cover' }} className="post-card-image" fixed={post.localImage.childImageSharp.fluid} />
                    :
                    null
                    // <PlaceHolderImageQuery imageProps={{
                    //     style: { width: "100%", height: '20vh', backgroundColor: 'transparent' },
                    //     imgStyle: { objectFit: 'cover' },
                    //     className: 'post-card-image'
                    // }} />
                } */}
                {/* {JSON.stringify(post.localImage)} */}
                {/* {post.featured && <span>Featured</span>} */}
                <section className="post-feed">
                    <PostCardList posts={products} color={color} />
                </section>
                {/* {products.map((p, i) => <PostCard post={p.node} key={i} />)} */}
            </header>
            {/* <section className="post-card-excerpt">{post.excerpt.slice(0, 200)}</section> */}
            <footer className="post-card-footer">
                <div className="post-card-footer-left">
                    {/* <div className="post-card-avatar">
                        {post.primary_author.profile_image ?
                            <img className="author-profile-image" src={post.primary_author.profile_image} alt={post.primary_author.name} /> :
                            <img className="default-avatar" src="/images/icons/avatar.svg" alt={post.primary_author.name} />
                        }
                    </div> */}
                    {/* <span>{post.html}</span> */}
                </div>
                {/* <div className="post-card-footer-right">
                    <div>{readingTime}</div>
                </div> */}
            </footer>
        </div>
    )
}

const ProductItem = ({ post }) => {
    return <div className="absolute">{post.title}</div>
}

CategoryCard.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        featured: PropTypes.bool,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
}

export default CategoryCard
