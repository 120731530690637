import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout, PostCard, Pagination } from '../components/common'
import { MetaData } from '../components/common/meta'
import ProductCarousel from '../components/common/ProductCarousel'
import CategoryCard from "../components/common/CategoryCard"

/**
* Main index page (home page)
*
* Loads all posts from Ghost and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in /utils/siteConfig.js under `postsPerPage`.
*
*/
const colors = [`green`, `indigo`, `purple`, `yellow`, `blue`, `gray`, `pink`]
const Index = ({ data, location, pageContext }) => {
    const products = data.allGhostPost.edges
    const categories = data.allGhostTag.edges

    return (
        <>
            <MetaData location={location} />
            <Layout isHome={true}>

                <div className={`p-6 bg-${colors[0]}-50`}>
                    <div className="container">
                        <h1 className=" m-6 text-5xl md:text-6xl lg:text-9xl font-sans opacity-70">Products</h1>
                    </div>
                </div>
                <section id="products">
                    {categories.map(({ node }, i) => {
                        const color = colors[i % colors.length]
                        return (
                            <div className={` p-6 bg-${color}-50`} key={i}>
                                <div className="container">
                                    <CategoryCard color={color} key={node.id} post={node} products={products.filter(p => p.node.tags.some(tag => node.slug == tag.slug))} />
                                </div>
                            </div>)
                    })}
                </section>

                <hr />
                <h1 className="m-6 text-5xl md:text-6xl lg:text-6xl font-sans opacity-70">You may also like</h1>
                <ProductCarousel posts={products} />

                {/* <Pagination pageContext={pageContext} /> */}
                {/* </div> */}
            </Layout>
        </>
    )
}

Index.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Index

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GhostPostQuery {

    allGhostTag(filter:{slug:{regex:"/products-/"}}){
    edges {
    node {
          ...GhostTagFields
    }
    }
    }

    allGhostPost(
        sort: { order: DESC, fields: [published_at] }
        filter:{tags:{elemMatch:{slug:{regex:"/products-/"}}}}
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
  }
`
