import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import PostCard from './PostCard';
const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        // slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

export default function ProductCarousel({ posts }) {
    return (
        <div style={{display: 'block', width: '100%'}}>

            <Carousel
                // swipeable={false}
                // draggable={false}
                showDots={false}
                responsive={responsive}
                // ssr={true} // means to render carousel on server-side.
                // width="100%"
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={1500}
                // keyBoardControl={true}
                // customTransition="all .5"
                // transitionDuration={500}

                // containerClass="carousel-container"
                // removeArrowOnDeviceType={["tablet", "mobile"]}
                deviceType={"mobile"}
                // dotListClass="custom-dot-list-style"
                // itemClass="carousel-item-padding-40-px"
            >
                {posts.map(({ node }) => (
                    // The tag below includes the markup for each post - components/common/PostCard.js
                    <PostCard key={node.id} post={node}/>
                ))}
            </Carousel>
        </div>
    )
}
